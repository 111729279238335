import { FooterComponentProps, FooterDataProps, FooterRedirectLinksProps ,TwitterIcon } from "#/globalTypes";
import { createContext, PropsWithChildren, useState } from "react";

export interface FooterContextProps {
  footerData: FooterDataProps[];
  setFooterData: React.Dispatch<React.SetStateAction<FooterDataProps[]>>;
  footerCopyrightMsg: string;
  setFooterCopyrightMsg: React.Dispatch<React.SetStateAction<string>>;
  footerComponent: FooterComponentProps;
  setFooterComponent: React.Dispatch<React.SetStateAction<FooterComponentProps>>;
  footerRedirectLinksData: FooterRedirectLinksProps[];
  setFooterRedirectLinksData: React.Dispatch<React.SetStateAction<FooterRedirectLinksProps[]>>;
  twitterIcon:TwitterIcon;
  setTwitterIcon: React.Dispatch<React.SetStateAction<TwitterIcon>>;
}

export const FooterContext = createContext<FooterContextProps>({} as FooterContextProps);

const FooterProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [footerData, setFooterData] = useState<FooterDataProps[]>([]);
  const [footerCopyrightMsg, setFooterCopyrightMsg] = useState<string>('');
  const [twitterIcon, setTwitterIcon] = useState<TwitterIcon>({});
  const [footerComponent, setFooterComponent] = useState<FooterComponentProps>({} as FooterComponentProps);
  const [footerRedirectLinksData, setFooterRedirectLinksData] = useState<FooterRedirectLinksProps[]>([]);

  return (
    <FooterContext.Provider value={{ footerRedirectLinksData, setFooterRedirectLinksData, footerComponent, setFooterComponent, footerData, setFooterData, footerCopyrightMsg, setFooterCopyrightMsg, twitterIcon, setTwitterIcon }}>
      {children}
    </FooterContext.Provider>
  );
};
export default FooterProvider;
