import axios from 'axios';
import { initialTags } from '@/constants';
import { ContentfulLink, HomePageProduct } from 'globalTypes';
import postApiCalls from '@/lib/api';

export const logout = async () => {
  try {
    const response = await postApiCalls({url:'/api/auth/logout'});
    return response.data;
  } catch (err: any) {
    return err;
  }
};

export const tagsToParse = (target: string[]) => {
  var obj = { ...initialTags };
  for (var i = 0; i < target?.length; i++) {
    var split = target[i].split(':');
    //@ts-ignore
    obj[split[0].trim()] = split[1].trim();
  }
  return obj;
};

export const transformProducts = (
  products: Record<string, any>
): HomePageProduct[] => {
  return products?.map((product: Record<string, any>) => {
    const node = product?.node?.variants?.edges[0]?.node;
    const {
      id: shopifyProductId,
      title,
      description,
      productType,
      handle,
      images,
      options,
      priceRange,
      tags,
      availableForSale,
    } = node.product;

    const productId = extractProductIdFromShopifyId(shopifyProductId);
    const id = node.id;
    const imageUrl = images?.edges[0]?.node?.url;
    const price = priceRange?.minVariantPrice?.amount;
    const optionData = options?.map((option: Record<string, any>) => ({
      name: option?.name,
      values: option?.values,
    }));

    return {
      productId,
      id,
      title,
      description,
      productType,
      handle,
      imageUrl,
      price,
      options: optionData,
      tags: tags,
      productColors: node.product.options.find(
        (item: Record<string, any>) => item.name === 'color'
      )?.values,
      availableForSale,
    };
  });
};

export const extractLinkFromContentfulLongText = (
  contentfulLongText: string
): ContentfulLink => {
  if (!contentfulLongText) {
    return {
      href: '',
      text: '',
    };
  }

  const href = contentfulLongText.substring(
    contentfulLongText.indexOf('(') + 1,
    contentfulLongText.lastIndexOf(')')
  );

  const text = contentfulLongText.substring(
    contentfulLongText.indexOf('[') + 1,
    contentfulLongText.lastIndexOf(']')
  );

  return {
    href,
    text,
  };
};

export const extractProductIdFromShopifyId = (
  shopifyProductId: string
): string => {
  if (!shopifyProductId) {
    return '';
  }

  const productId = shopifyProductId.substring(
    shopifyProductId.lastIndexOf('/') + 1
  );

  return productId;
};

export const sortProducts = (products: HomePageProduct[]) => {
  return products?.sort(function (a, b) {
    return a.handle.localeCompare(b.handle, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
};
