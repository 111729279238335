interface PlatformInfoProps {
  children: string;
  lightFont?: boolean;
  uppercase?: boolean;
}
export default function PlatformInfo(props: PlatformInfoProps) {
  const { lightFont, children, uppercase } = props;
  return (
    <p
      className={`text-xs ${lightFont ? `font-extralight` : `font-light`} ${
        uppercase ? `uppercase` : `capitalize`
      }`}
    >
      {children}{' '}
    </p>
  );
}
