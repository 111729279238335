import { LinksDataProps, checkoutDataProps } from '#/globalTypes';
import { createContext, PropsWithChildren, useState } from 'react';

export interface ContentContextProps {
  linksData: LinksDataProps;
  setLinksData: React.Dispatch<React.SetStateAction<LinksDataProps>>;
  checkoutData: checkoutDataProps;
  setCheckoutData: React.Dispatch<React.SetStateAction<checkoutDataProps>>;
}

export const ContentContext = createContext<ContentContextProps>(
  {} as ContentContextProps
);

const ContentProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const linksObj = { name: '', alt: '', link: '' };
  const [linksData, setLinksData] = useState<LinksDataProps>({
    logo: linksObj,
  });
  const [checkoutData, setCheckoutData] = useState<checkoutDataProps>({});
  return (
    <ContentContext.Provider
      value={{ linksData, setLinksData, checkoutData, setCheckoutData }}
    >
      {children}
    </ContentContext.Provider>
  );
};
export default ContentProvider;
