import { Ilink } from 'globalTypes';
import Link from 'next/link';
import { useContext } from 'react';
import {
  HeaderContextProps,
  HeaderProductsContext,
} from '@/context/HeaderContext';
import Image from 'next/image';

interface FilterDevicesProps {
  showMenu: boolean;
  closeNav: () => void;
}

export default function FilterDevices(props: FilterDevicesProps) {
  const { headerProductsData, ctaIconImage } = useContext<HeaderContextProps>(
    HeaderProductsContext
  );
  const ctaIcon = ctaIconImage[0]?.fields?.file?.url;

  return (
    <>
      <div
        role="tabpanel"
        className={!props.showMenu ? 'tabPane' : ''}
        id="menu1"
      >
        <div className="container">
          <ul className="siteNavSubmenu">
            <li>
              <Link
                href={headerProductsData?.ctaLink || ''}
                onClick={() => props.closeNav()}
                data-tracking-id="rental-nav-link"
                className="siteNavLink items-center"
              >
                {ctaIcon && (
                  <Image
                    src={`http:${ctaIcon}`}
                    className={'mr-1 w-5 inline iconAlignment'}
                    data-tracking-id="rental-nav-icon"
                    alt="Subscribe Icon"
                    width={20}
                    height={20}
                  />
                )}

                <span className="">{headerProductsData.cta}</span>
              </Link>
            </li>

            {headerProductsData?.links?.map((productInfo: Ilink) => (
              <li key={productInfo.name}>
                <a
                  href={productInfo.link}
                  data-tracking-id={`nav-${productInfo.name}-link`}
                  onClick={() => props.closeNav()}
                  className="siteNavLink"
                >
                  {productInfo.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="tabPane" id="menu2">
        <div className="container">
          <ul className="siteNavSubmenu"></ul>
        </div>
      </div>
    </>
  );
}
