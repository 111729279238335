import { ContactUsDataProps, HeroSectionDataProps } from "#/globalTypes";
import { createContext, PropsWithChildren, useState } from "react";

export interface HelpCenterContextProps {
  helpCenterHeroData: HeroSectionDataProps[];
  helpCenterContactUsData:ContactUsDataProps[];
  setHelpCenterHeroData: React.Dispatch<React.SetStateAction<HeroSectionDataProps[]>>;
  setHelpCenterContactUsData: React.Dispatch<React.SetStateAction<ContactUsDataProps[]>>;
}

export const HelpCenterContext = createContext<HelpCenterContextProps>({} as HelpCenterContextProps);

const HelpCenterProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [helpCenterHeroData, setHelpCenterHeroData] = useState<HeroSectionDataProps[]>([]);
  const [helpCenterContactUsData, setHelpCenterContactUsData] = useState<ContactUsDataProps[]>([]);

  const value={
    helpCenterHeroData,
    helpCenterContactUsData,
    setHelpCenterHeroData,
    setHelpCenterContactUsData,
  }
  return (
    <HelpCenterContext.Provider {...{value}}>
      {children}
    </HelpCenterContext.Provider>
  );
};
export default HelpCenterProvider;
