import { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import '@/styles/globals.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import MainLayout from '@/layouts/MainLayout';
import { createApolloClient } from '@/lib/ApolloClient';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import CartContext from '@/context/CartContext';
import UserContext from '@/context/UserContext';
import FAQProvider from '@/context/FaqContext';
import HelpCenterProvider from '@/context/HelpCenterContext';
import ErrorBoundary from '@/layouts/ErrorBoundary';
import FallbackScreen from '@/components/organisms/fallbackscreen';
import FooterProvider from '@/context/FooterContext';
import HeaderProvider from '@/context/HeaderContext';
import ErrorProvider from '@/context/ErrorContext';
import ContentProvider from '@/context/ContentContext';
import { datadogRum } from '@datadog/browser-rum';
import logger from '@/lib/logger';
import { GTM_ID } from '@/lib/gtm';
import { getUrls } from '@/lib/Urls';

export default function App({ Component, pageProps }: AppProps) {
  const [apolloClient, setApolloClient] = useState<
    ApolloClient<NormalizedCacheObject>
  >({} as ApolloClient<NormalizedCacheObject>);

  useEffect(() => {
    async function initializeApolloClient() {
      const client = await createApolloClient();
      setApolloClient(client);
    }
    try {
      const url = getUrls();
      if (url.nodeEnv === 'production') {
        datadogRum.init({
          applicationId: url.dataLogId as string,
          clientToken: url.dataDogClientToken as string,
          site: 'datadoghq.com',
          service: 'gazelle-flex',
          env: url.publicEnvoirment,
          allowedTracingUrls: [
            '</api>',
            /https:\/\/api{-dev|-qa}[0,1].ecoatm.com/i,
          ],
          // Specify a version number to identify the deployed version of your application in Datadog
          // version: '1.0.0',
          sessionSampleRate: 100,
          sessionReplaySampleRate: 1,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
        });
      }
    } catch (err) {
      logger.warn(err, 'DataDog RUM Error. No RUM initiated');
    }

    initializeApolloClient();
  }, []);

  if (!apolloClient) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Head>
        <title>Gazelle Flex</title>
        <meta name="description" content="Gazelle Flex - Rentals" />
        <script id="google-tag-manager">
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
        `}
        </script>
      </Head>

      <ContentProvider>
        <UserContext>
          <CartContext>
            <ErrorProvider>
              <main style={{}}>
                <HelpCenterProvider>
                  <FooterProvider>
                    <FAQProvider>
                      <HeaderProvider>
                        <ErrorBoundary fallback={<FallbackScreen />}>
                          <MainLayout>
                            <ApolloProvider client={apolloClient}>
                              <Component {...pageProps} />
                            </ApolloProvider>
                          </MainLayout>
                        </ErrorBoundary>
                      </HeaderProvider>
                    </FAQProvider>
                  </FooterProvider>
                </HelpCenterProvider>
              </main>
            </ErrorProvider>
          </CartContext>
        </UserContext>
      </ContentProvider>
    </>
  );
}
