import Cart from '../atoms/Cart';
import PlatformInfo from '../atoms/PlatformInfo';
import { BiUserCircle } from 'react-icons/bi';
import { BsChevronDown } from 'react-icons/bs';
import { useState } from 'react';
import { useUser } from '@/context/UserContext';
import { useRouter } from 'next/router';
import { useCart } from '@/context/CartContext';
import { getUrls } from '@/lib/Urls';

interface CartContainerProps {
  cartHeaderText: string;
}

export default function CartContainer(props: CartContainerProps) {
  const { cartHeaderText } = props;
  const url = getUrls();
  const [isDisplayingLogin, setIsDisplayingLogin] = useState<boolean>(false);
  const { loggedIn, user } = useUser();
  const { removeItemsFromCart } = useCart();

  const router = useRouter();

  function goToAccount() {
    router.push(`${url.gazelleUrl}/account/`);
  }

  function goToLogin() {
    // Login Subscription Side Route (Post MVP)
    //router.push('/login');
    // after initial testing on QA, for cookie limitation endpoint here we be determined by Environment Variable
    router.push(
      `${url.gazelleUrl}/account/login?return_url=${window.location.origin}${router.asPath}`
    );
  }

  function goToCreateAccount() {
    localStorage.clear();
    router.push(
      `${url.gazelleUrl}/account/register?return_url=${window.location.origin}${router.asPath}`
    );
  }

  async function handleLogout() {
    await removeItemsFromCart();
    router.push(
      `${
        url.gazelleUrl
      }/account/login?logout&logout_return_url=${encodeURIComponent(
        window.location.origin
      )}`
    );
  }

  return (
    <section className="xl:w-[430px] flex flex-row items-center justify-end ">
      <div className="ml-2 xl:ml-0 h-2/3 flex flex-row items-center relative">
        <BiUserCircle
          size={36}
          className="pr-2"
          data-tracking-id="account-icon"
        />
        <button
          onClick={() => setIsDisplayingLogin(!isDisplayingLogin)}
          className="xl:hidden"
          data-tracking-id="drop-down"
        >
          <BsChevronDown size={20} />
        </button>

        {/* Dropdown for isDisplayingLogin on large screens */}
        {isDisplayingLogin && (
          <div className="xl:hidden absolute mt-32 right-[-2px] w-[152px] rounded-sm border border-[#D2D2D2] shadow-md">
            <div className="rounded-sm bg-white shadow-xs">
              <div className="py-1 ">
                {loggedIn && user?.userEmail ? (
                  <>
                    <button
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      onClick={goToAccount}
                    >
                      {user.firstName}
                    </button>
                    <button
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      onClick={handleLogout}
                    >
                      LOGOUT
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={goToLogin}
                      className="block px-6 py-2 text-md text-[#ED722E] hover:bg-gray-100 hover:text-gray-900"
                    >
                      Login
                    </button>
                    <button
                      onClick={goToCreateAccount}
                      className="block px-6 py-2 text-md text-[#ED722E] hover:bg-gray-100 hover:text-gray-900"
                    >
                      Create Account
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Visible on large screens */}
        <div
          className={
            'hidden xl:flex flex-row items-center divide-x-2 divide-fontGrey text-sx font-light uppercase'
          }
        >
          {loggedIn && user?.userEmail ? (
            <>
              {/* //@Todo: This needs to be refactored to show First & Last Name not email split at @ , Backend needs to provide name*/}
              <span className="mr-1 cursor-pointer" onClick={goToAccount}>
                {user.firstName}
              </span>
              <span className="p-1 cursor-pointer" onClick={handleLogout}>
                LOGOUT
              </span>
            </>
          ) : (
            <>
              <button
                onClick={goToLogin}
                className="px-4 flex flex-row items-center"
                data-tracking-id="login"
              >
                <PlatformInfo uppercase>Login</PlatformInfo>
              </button>
              <button
                onClick={goToCreateAccount}
                className="px-4"
                data-tracking-id="create-account"
              >
                <PlatformInfo uppercase>Create Account</PlatformInfo>
              </button>
            </>
          )}
        </div>
      </div>
      <div className="w-28 xl:w-40 px-2">
        <Cart cartHeaderText={cartHeaderText} />
      </div>
    </section>
  );
}
