import React from 'react';
import { useRouter } from 'next/router';
import TESTIDs from '@/lib/TESTIDs';

const testIds = TESTIDs?.fallbackscreen;
const FallbackScreen = () => {
  const router = useRouter();

  return (
    <div
      className="flex justify-center m-5"
      data-testid={testIds?.fallbackscreen_main_container}
    >
      <div className="w-[500px] p-4 m-4 rounded-lg border-2 border-orange-600">
        <h3
          className="font-semibold text-lg"
          data-testid={testIds?.fallbackscreen_heading}
        >
          A problem has occured
        </h3>
        <p data-testid={testIds?.fallbackscreen_message}>
          We are working to fix it
        </p>
        <div className="flex gap-2 my-4">
          <button
            type="button"
            onClick={() => router.reload()}
            className="pt-1 px-2 rounded-md border-2 border-orange-600 hover:border-orange-400 hover:text-orange-600"
            data-testid={testIds?.fallbackscreen_reload_button}
          >
            Reload
          </button>
          <button
            type="button"
            onClick={() => router.push('/')}
            className="pt-1 px-2 rounded-md border-2 border-orange-600 hover:border-orange-400 hover:text-orange-600"
            data-testid={testIds?.fallbackscreen_home_button}
          >
            Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default FallbackScreen;
