import Link from 'next/link';
import { FaChevronLeft } from 'react-icons/fa';
import Image from 'next/image';

export default function DarkHeader() {
  return (
    <section className={'w-full h-20 bg-black px-48'}>
      <div
        className={'w-1/2 h-full flex flex-row justify-between items-center'}
      >
        <Link
          href={'/cart'}
          className={'flex flex-row text-white items-center'}
        >
          <FaChevronLeft color={'#fff'} className={'mr-2'} />
          Return to cart
        </Link>
        <Image
          src={
            'https://buy.gazelle.com/cdn/shop/t/310/assets/gazelle-logo.svg?v=161159857692628355091691098886'
          }
          width={100}
          height={100}
          alt={'Gazelle logo no bg'}
        />
      </div>
    </section>
  );
}
