import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react';
import { IuserData, IshippingInfo } from '#/globalTypes';
import { getShopifyCustomer } from '@/lib/Storelib';
import { logout } from '../utilities';
import logger from '@/lib/logger';
import postApiCalls from '@/lib/api';

const authResponse = {
  customerAccessToken: '',
  redirectUrl: '',
  createdAt: 0,
};

type IauthResponse = typeof authResponse;

type IuserContext = {
  user: IuserData;
  authResponse: IauthResponse | null;
  addAuthResponse: (response: IauthResponse) => void;
  authenticationUserServerSide: any;
  loggedIn: boolean;
  activeSubscriptionPlan: boolean;
  logOut: () => void;
  setShippingInfo(shippingInfo: IshippingInfo): void;
  shippingInfoComplete: boolean;
};

export const UserContextStore = createContext<IuserContext | null>(null);
export const useUser = (): IuserContext => {
  const userData = useContext(UserContextStore);
  if (!userData) throw new Error('User context object incomplete');
  return userData;
};
const initialUserState = {
  userEmail: '',
  handle: '',
  name: '',
  firstName: '',
  lastName: '',
  shopifyToken: {
    token: '',
    expiresAt: '',
  },
  shippingInfo: {
    country: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
  },
};
const UserContext: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<IuserData>(initialUserState);
  const [authRes, setAuthRes] = useState<IauthResponse | null>(null);
  const [loggedIn, setLoggedin] = useState(false);
  const [activeSubscriptionPlan, setActiveSubscriptionPlan] = useState(false);
  const [shippingInfoComplete, setShippingInfoComplete] = useState(false);

  //Note: This is for post-mvp, used with login page and login api route
  const addAuthResponse = (response: IauthResponse) => {
    setAuthRes(response);
  };

  const authenticationUserServerSide = async (customerAccessToken: any) => {
    //@todo using shipping mock data - post MVP
    if (customerAccessToken) {
      // const newUserObj = {
      // 	userEmail: user_email,
      // 	shopifyToken: {
      // 		token: `${customerAccessToken}`,
      // 		expiresAt: `1690914470`
      // 	},
      // 	//Need to make Shopify Call to get User Shipping Info if Stored, if not set to empty Object
      // 	shippingInfo: {
      // 		country: "USA",
      // 		firstName: "John",
      // 		lastName: "Doe",
      // 		address: "123 Washington Ave",
      // 		apartMent: "1A",
      // 		city: "San Jose",
      // 		state: "CA",
      // 		zipCode: "94132",
      // 		phone: "14044491548"
      // 	}
      // };
      // Call GraphQL for Customer Info
      const shopifyCustomerInfo: any = await getShopifyCustomer(
        customerAccessToken
      );

      const newUserObj = {
        userEmail: shopifyCustomerInfo.data.customer?.email,
        name: shopifyCustomerInfo.data.customer?.name,
        firstName: shopifyCustomerInfo.data.customer?.firstName,
        lastName: shopifyCustomerInfo.data.customer?.lastName,
        shopifyToken: {
          token: `${customerAccessToken}`,
          expiresAt: `1690914470`,
        },
        shippingInfo: shopifyCustomerInfo.data.customer?.defaultAddress,
      };

      setUser(newUserObj);
      setLoggedin(true);
      //@todo set authRest value - POST-MVP
      //@todo set setShippingInfoComplete if shipping is available

      try {
        const response = await postApiCalls({
          url: '/api/auth/eligiblecheck',
          body: {
            customerAccessToken,
          },
        });
        if (!response.data.userEligible) {
          setActiveSubscriptionPlan(true);
        } else {
          setActiveSubscriptionPlan(false);
        }
      } catch (err: any) {
        logger.error(err);
      }
    } else {
      setLoggedin(false);
      setAuthRes(null);
      setUser(initialUserState);
      setShippingInfoComplete(false);
    }
  };

  const isInfoComplete = (shippingInfo: IshippingInfo): boolean => {
    return (
      shippingInfo.country !== '' &&
      shippingInfo.firstName !== '' &&
      shippingInfo.lastName !== '' &&
      shippingInfo.address1 !== '' &&
      shippingInfo.city !== '' &&
      shippingInfo.state !== '' &&
      shippingInfo.zip !== '' &&
      shippingInfo.phone !== ''
    );
  };
  function setShippingInfo(shippingInfo: IshippingInfo) {
    if (isInfoComplete(shippingInfo) && loggedIn && user.userEmail) {
      setUser((prev) => ({
        ...prev,
        shippingInfo,
      }));
    }
  }

  // Do not use until SSO is set up within app
  // This may not be functional yet
  const logOut = async () => {
    const response = await logout();
    if (response.status.message === 'OK') {
      setAuthRes(null);
      setLoggedin(false);
      setUser(initialUserState);
      window.location.href = '/';
    }
  };

  useEffect(() => {
    if (user.shippingInfo) {
      setShippingInfoComplete(isInfoComplete(user.shippingInfo));
    }
  }, [user]);

  const value = {
    user,
    authResponse: authRes,
    addAuthResponse,
    authenticationUserServerSide,
    loggedIn,
    logOut,
    activeSubscriptionPlan,
    setShippingInfo,
    shippingInfoComplete,
  };

  return (
    <UserContextStore.Provider {...{ value }}>
      {children}
    </UserContextStore.Provider>
  );
};

export default UserContext;
