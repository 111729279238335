import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
  useCallback,
} from 'react';

import { ShopifyCartProps, IcheckoutInfo } from '#/globalTypes';
import { getInitiationFee } from '@/lib/Storelib';
import { useSearchParams } from 'next/navigation';

interface IcartContext {
  rawCart: IcheckoutInfo | null;
  addRawCart: (cart: IcheckoutInfo) => void;
  cartId: string;
  cartItems: ShopifyCartProps[];
  initiationFee: Record<string, any> | null;
  removeItemsFromCart(): Promise<void>;
  checkoutWebUrl: string;
}

export const CartContextStore = createContext<IcartContext | null>(null);
export const useCart = (): IcartContext => {
  const cartData = useContext(CartContextStore);
  if (!cartData) throw new Error('Cart context object incomplete');
  return cartData;
};

const CartContext: React.FC<PropsWithChildren> = ({ children }) => {
  const [rawCart, setRawCart] = useState<IcheckoutInfo | null>(null);
  const [cartId, setCartId] = useState('');
  const [cartItems, setCartItems] = useState<ShopifyCartProps[]>([]);
  const [initiationFee, setInitiationFee] = useState<null | Record<
    string,
    any
  >>(null);
  const [checkoutWebUrl, setCheckoutWebUrl] = useState('');
  const searchParams = useSearchParams();

  const addRawCart = (cart: IcheckoutInfo) => {
    setRawCart(cart);
  };

  async function removeItemsFromCart() {
    localStorage.removeItem('checkout');
    setRawCart(null);
    setCartItems([]);
    setCheckoutWebUrl('');
    setCartId('');
  }
  const extractCartItems = useCallback(() => {
    if (rawCart && rawCart?.checkoutProducts) {
      const cartLine = [
        {
          carrier: rawCart?.tags?.Carrier,
          storageSpace: rawCart?.tags?.Storage,
          color: rawCart?.checkoutProducts?.phone?.phoneColor,
          cartLineId: '1',
          quantity: '1',
          productName: rawCart?.checkoutProducts?.phone?.title,
          productSlug: rawCart?.checkoutProducts?.phone?.handle,
          src: rawCart?.checkoutProducts?.phone?.photo,
          rentingPrice: rawCart?.checkoutProducts?.phone?.price,
          productType: rawCart?.checkoutProducts?.phone?.productType,
          subtotalPrice: rawCart?.subtotalCheckoutPrice as number,
        },
      ];
      //@ts-ignore
      setCartItems(cartLine);
    } else {
      setCartItems([]);
    }
  }, [rawCart]);

  useEffect(() => {
    const logout = searchParams?.get('logout');
    if (logout != null) {
      removeItemsFromCart();
    }

    const preViousCart = localStorage.getItem('checkout');
    if (!rawCart && preViousCart) {
      const cart: IcheckoutInfo = JSON.parse(preViousCart);
      addRawCart(cart);
    }
  }, [rawCart]);
  useEffect(() => {
    getInitiationFee().then((data) => {
      if (data) {
        setInitiationFee(data?.data?.product);
      }
    });
  }, []);

  useEffect(() => {
    if (rawCart) {
      extractCartItems();
      setCartId(rawCart?.cartId);
      setCheckoutWebUrl(rawCart?.checkoutWebUrl);
    }
  }, [rawCart, extractCartItems]);

  const value = {
    rawCart,
    addRawCart,
    cartId,
    checkoutWebUrl,
    cartItems,
    initiationFee,
    removeItemsFromCart,
  };
  return (
    <CartContextStore.Provider {...{ value }}>
      {children}
    </CartContextStore.Provider>
  );
};

export default CartContext;
