import { gql } from "@apollo/client";

export const REMOVE_CART_ITEMS = gql`
	mutation CartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
		cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
			cart {
				id
				createdAt
				lines(first: 10) {
					edges {
						node {
							id
							quantity
							merchandise {
								... on ProductVariant {
									id
									title
									selectedOptions {
										name
										value
									}
									product {
										title
										handle
										productType
										images(first: 1) {
											edges {
												node {
													url
												}
											}
										}
										priceRange {
											minVariantPrice {
												amount
											}
										}
									}
								}
							}
						}
					}
				}
				cost {
					subtotalAmount {
						amount
						currencyCode
					}
				}
			}
		}
	}
`;

export const CREATE_CART = gql`
mutation CartCreate($merchandiseId: ID!, $sellingPlanId: ID!, $initiationFee: ID!, $storageSpace: String!, $carrier: String!) {
	cartCreate(
		input: {
			lines: [
				{ 
				    attributes: [
                            {
                            key: "Capacity",
                            value: $storageSpace
                            },
                            {
                            key: "Carrier",
                            value: $carrier
                            },
                            {
                            key: "Rental",
                            value: "First rental payment. Payment due monthly."
                            }
                         ],
				    quantity: 1, merchandiseId: $merchandiseId, sellingPlanId: $sellingPlanId},
				{ quantity: 1, merchandiseId: $initiationFee}
			]
		}
	) {
		cart {
			id
			createdAt
			checkoutUrl
			lines(first: 10) {
				edges {
					node {
						id
						quantity
						merchandise {
							... on ProductVariant {
								id
								title
								image {
									src
								  }
								selectedOptions {
									name
									value
								}
								product {
									title
									handle
									productType
									images(first: 1) {
										edges {
											node {
												url
											}
										}
									}
									priceRange {
										minVariantPrice {
											amount
										}
									}
								}
							}
						}
					}
				}
			}
			cost {
				subtotalAmount {
					amount
					currencyCode
				}
			}
		}
	}
}
`;

export const CREATE_CHECKOUT_ITEM = gql`
	mutation CheckoutCreate($variantId: ID!) {
		checkoutCreate(
			input: {
				lineItems: [
					{ variantId: $variantId, quantity: 1 }
				]
			}
		) {
			checkout {
				updatedAt
				id
				lineItems(first: 50) {
					edges {
						node {
							title
							quantity
							id
							variant {
								id
								product {
									productType
									handle
									title
								}
								image {
									url
								}
								price {
									amount
								}
							}
						}
					}
				}
				subtotalPrice {
					amount
				}
			}
			checkoutUserErrors {
				code
				field
				message
			}
		}
	}
`;

export const REMOVE_CHECKOUT_ITEMS = gql`
	mutation CheckoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
		checkoutLineItemsRemove(
			checkoutId: $checkoutId
			lineItemIds: $lineItemIds
		) {
			checkout {
				updatedAt
				id
				lineItems(first: 50) {
					edges {
						node {
							title
							quantity
							id
							variant {
								id
								product {
									productType
									handle
									title
									condition: metafield(
										namespace: "phone"
										key: "condition"
									) {
										value
									}
									storageSpace: metafield(
										namespace: "phone"
										key: "storageSpace"
									) {
										value
									}
									carrier: metafield(
										namespace: "phone"
										key: "carrier"
									) {
										value
									}
									phoneColor: metafield(
										namespace: "phone"
										key: "phoneColor"
									) {
										value
									}
									contentfulSlug: metafield(
										namespace: "phone"
										key: "contentfulSlug"
									) {
										value
									}
								}
								image {
									url
								}
								priceV2 {
									amount
								}
							}
						}
					}
				}
				subtotalPrice {
					amount
				}
			}
		}
	}
`;

export const ADD_LINE_ITEMS_TO_CHECKOUT = gql`
	mutation checkoutLineItemsAdd(
		$checkoutId: ID!
		$lineItems: [CheckoutLineItemInput!]!
	) {
		checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
			checkout {
				updatedAt
				id
				lineItems(first: 50) {
					edges {
						node {
							title
							quantity
							id
							variant {
								id
								product {
									productType
									handle
									title
									condition: metafield(
										namespace: "phone"
										key: "condition"
									) {
										value
									}
									storageSpace: metafield(
										namespace: "phone"
										key: "storageSpace"
									) {
										value
									}
									carrier: metafield(
										namespace: "phone"
										key: "carrier"
									) {
										value
									}
									phoneColor: metafield(
										namespace: "phone"
										key: "phoneColor"
									) {
										value
									}
									contentfulSlug: metafield(
										namespace: "phone"
										key: "contentfulSlug"
									) {
										value
									}
								}
								image {
									url
								}
								priceV2 {
									amount
								}
							}
						}
					}
				}
				subtotalPrice {
					amount
				}
			}
			checkoutUserErrors {
				code
				field
				message
			}
		}
	}
`;

export const UPDATE_CHECKOUT_LINE_ITEMS = gql`
	mutation checkoutLineItemsUpdate(
		$checkoutId: ID!
		$lineItems: [CheckoutLineItemUpdateInput!]!
	) {
		checkoutLineItemsUpdate(
			checkoutId: $checkoutId
			lineItems: $lineItems
		) {
			checkout {
				updatedAt
				id
				lineItems(first: 50) {
					edges {
						node {
							title
							quantity
							id
							variant {
								id
								product {
									productType
									handle
									title
									condition: metafield(
										namespace: "phone"
										key: "condition"
									) {
										value
									}
									storageSpace: metafield(
										namespace: "phone"
										key: "storageSpace"
									) {
										value
									}
									carrier: metafield(
										namespace: "phone"
										key: "carrier"
									) {
										value
									}
									phoneColor: metafield(
										namespace: "phone"
										key: "phoneColor"
									) {
										value
									}
									contentfulSlug: metafield(
										namespace: "phone"
										key: "contentfulSlug"
									) {
										value
									}
								}
								image {
									url
								}
								priceV2 {
									amount
								}
							}
						}
					}
				}
				subtotalPrice {
					amount
				}
			}
			checkoutUserErrors {
				code
				field
				message
			}
		}
	}
`;

export const CREATE_CUSTOMER_ACCESS_TOKEN = gql`
	mutation customerAccessTokenCreate(
		$input: CustomerAccessTokenCreateInput!
	) {
		customerAccessTokenCreate(input: $input) {
			customerUserErrors {
				code
				field
				message
			}
			customerAccessToken {
				accessToken
				expiresAt
			}
		}
	}
`;

export const CREATE_CUSTOMER_ACCESS_TOKEN_WITH_MULTIPASS = gql`
	mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) {
		customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
			customerAccessToken {
				accessToken
				expiresAt
			}
			customerUserErrors {
				code
				field
				message
			}
		}
	}
`;

export const ASSOCIATE_CUSTOMER_WITH_CHECKOUT = gql`
	mutation associateCustomerWithCheckout(
		$checkoutId: ID!
		$customerAccessToken: String!
	) {
		checkoutCustomerAssociateV2(
			checkoutId: $checkoutId
			customerAccessToken: $customerAccessToken
		) {
			checkout {
				id
			}
			checkoutUserErrors {
				code
				field
				message
			}
			customer {
				id
			}
		}
	}
`;

export const CREATE_SHOPIFY_PAYMENTMETHOD = gql`
	mutation customerPaymentMethodCreditCardCreate(
		$billingAddress: BILLING_ADDRESS!
		$customerId: ID!
		$sessionId: SESSION_ID!
	) {
		customerPaymentMethod {
			id
			revokedReason
		}
		userErrors {
			field
			message
		}
	}
`;

export const COMPLETE_CHECKOUT = gql`
	mutation checkoutCompleteWithCreditCardV2(
		$checkoutId: ID!
		$payment: CreditCardPaymentInputV2!
	) {
		checkoutCompleteWithCreditCardV2(
			checkoutId: $checkoutId
			payment: $payment
		) {
			checkout {
				id
			}
			checkoutUserErrors {
				code
				field
				message
			}
			payment {
				id
			}
		}
	}
`;
