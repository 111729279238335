import Image from 'next/image';
import { useCart } from '@/context/CartContext';
import Link from 'next/link';
import Subscribtionwhite from '../../@images/orangesub.svg';

interface CartProps {
  cartHeaderText: string;
}

export default function Cart(props: CartProps) {
  const { cartHeaderText } = props;

  const { cartItems } = useCart();
  return (
    <>
      <Link
        href={'/cart'}
        className="miniCartLink items-center"
        data-tracking-id="mini-cart"
      >
        <Image
          className="align-middle"
          src={Subscribtionwhite}
          alt={'Subscribe'}
        />{' '}
        <span className="cartItemLabel">{cartHeaderText}</span>
        <span className="cartCounter">{cartItems.length}</span>
      </Link>
      <div className="w-80 rounded-md h-auto shadow-md absolute -bottom-28 right-0 hidden group-hover:block ">
        <div className="w-full h-14 bg-primary">
          <p className={'text-white uppercase font-light text-lg pl-2 pt-3'}>
            Shopping Cart
          </p>
        </div>
        <div>
          {cartItems.length === 0 ? (
            <div className="w-full h-14 py-4 bg-white">
              <p className="text-center">Your cart is empty</p>
            </div>
          ) : (
            <div className="w-full h-14 py-4 bg-white">
              <p className="text-center">Your cart has the following items</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
