const TESTIDs = {
    fallbackscreen:{
        fallbackscreen_main_container:"fallbackscreen_main_container",
        fallbackscreen_heading:"fallbackscreen_heading",
        fallbackscreen_message:"fallbackScreen_message",
        fallbackscreen_reload_button:"fallbackScreen_reload_button",
        fallbackscreen_home_button:"fallbackScreen_home_button",
    }
}

export default TESTIDs