import { gql } from "@apollo/client";

export const GET_PHONES = gql`
	{
		collectionByHandle(handle: "smartphones") {
			products(first: 10) {
				edges {
					node {
						title
						description
						variants(first: 1) {
							edges {
								node {
									price
								}
							}
						}
						images(first: 1) {
							edges {
								node {
									url
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_PHONE_DETAILS = gql`
	query Getcollection($handle: String!) {
		productByHandle(handle: $handle) {
			id
			title
			productType
			handle
			images(first: 50) {
				edges {
					node {
						url
						altText
					}
				}
			}
			options {
				id
				name
				values
			}
			variants(first: 10) {
					edges {
						node {
							id
							title
							sku
							price {
								amount
							}
							availableForSale
							selectedOptions {
								name
								value
							}
							image {
								url
								id
								width
								height
								altText
							}
						}
					}
				}
			priceRange {
				minVariantPrice {
					amount
				}
			}
			tags
			availableForSale
			sellingPlanGroups (first: 5) {
				nodes {
					sellingPlans (first: 5) {
						nodes {
							id
							name
						}
					}
				}
			}
		  }
	}
`;

export const GET_HOMEPAGE_PHONES = gql`
{
	collection(handle: "smartphones") {
		products(first: 3) {
			edges {
				node {
					variants(first: 1) {
						edges {
							node {
								id
								product {
									id
									title
									description
									productType
									handle
									images(first: 1) {
										edges {
											node {
												url
											}
										}
									}
									options {
										id
										name
										values
									}
									priceRange {
										minVariantPrice {
											amount
										}
									}
									tags
									availableForSale
								}
							}
						}
					}
				}
			}
		}
	}
  }
`;

//@todo: before updating handle in Shopify verify with backend processes.
export const GET_ONETIME_INITIATION_FEE_ITEM = gql`
	query GetProductByHandleCondensed {
		product(handle: "one-time-initiation-fee") {
			id
			title
			handle
			variants(first: 1) {
				edges {
					node {
						id
						price {
							amount
						}
					}
				}
			}
		}
	}
`;

export const GET_CUSTOMER_INFO = gql`
	query($customerAccessToken: String!){
		customer(
			customerAccessToken: $customerAccessToken
		) {
			id
			displayName
			firstName
			lastName
			phone
			email
			defaultAddress {
				name
				firstName
				lastName
				address1
				address2
				city
				country
				zip
				phone
				latitude
				longitude
			}
		}
	}
`;
