import { HeaderDataProps, CtaIconImage } from "#/globalTypes";
import { createContext, PropsWithChildren, useState } from "react";

export interface HeaderContextProps {
  headerProductsData: HeaderDataProps;
  setHeaderProductsData: React.Dispatch<React.SetStateAction<HeaderDataProps>>;
  cartHeaderText: string;
  setCartHeaderText: React.Dispatch<React.SetStateAction<string>>;
  ctaIconImage:CtaIconImage;
  setCtaIconImage: React.Dispatch<React.SetStateAction<CtaIconImage>>;
}

export const HeaderProductsContext = createContext<HeaderContextProps>({} as HeaderContextProps);

const HeaderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [headerProductsData, setHeaderProductsData] = useState<HeaderDataProps>({ "links":[],"cta":"" ,"ctaLink":""});
  const [cartHeaderText, setCartHeaderText] = useState<string>('');
  const [ctaIconImage, setCtaIconImage] = useState<CtaIconImage>({});

  return (
    <HeaderProductsContext.Provider value={{ headerProductsData, setHeaderProductsData, cartHeaderText, setCartHeaderText , ctaIconImage , setCtaIconImage }}>
      {children}
    </HeaderProductsContext.Provider>
  );
};
export default HeaderProvider;
