import React, { useContext, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { FaPhone, FaRss } from 'react-icons/fa';
import { GrInstagram, GrYoutube } from 'react-icons/gr';
import { ImFacebook2 } from 'react-icons/im';
import { MdEmail } from 'react-icons/md';
import { FooterContext, FooterContextProps } from '@/context/FooterContext';
import postApiCalls from '@/lib/api';

export default function Footer() {
  const { footerComponent, footerRedirectLinksData, twitterIcon } =
    useContext<FooterContextProps>(FooterContext);

  const [email, setEmail] = useState('');
  const inputRef = React.useRef(null);
  const [responseStatus, setResponseStatus] = useState(0);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const TwitterIcon = twitterIcon[0]?.fields?.file?.url;

  //@todo: future move this to a hoo
  const subscribe = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!emailRegex.test(email)) {
      return;
    }
    const url = 'https://d2xyrw75c4fk9m.cloudfront.net/graphql';
    const body = `{"query":"mutation SubscribeToNewsletter($input: NewsletterSubscribeInput!) { subscribeToNewsletter(input: $input) }","variables":{"input":{"emailAddress":"${email}","domain":"gazelle"}}}`;

    const response = await postApiCalls({ url: url, body: body });

    if (response.status === 200) {
      setResponseStatus(response.status);
    }
  };

  return (
    <section className={'w-full bg-footerDarkGrey h-auto'}>
      <div
        className={
          'w-full flex flex-col xl:flex-row justify-between px-4 py-8 xl:px-48 xl:py-10 text-bgLightGrey'
        }
      >
        <div className={'w-full xl:w-1/4 flex flex-row justify-between'}>
          <div className={'w-full flex items-start justify-start flex-col'}>
            <h4 className={'font-medium text-lg xl:text-3xl mb-5'}>
              {footerComponent?.texts?.contact}
            </h4>

            <div
              className={
                'w-full flex flex-col xl:justify-start xl:items-start '
              }
            >
              <div className={'flex flex-row mb-4 text-fontGrey'}>
                <FaPhone color={'#a3a3a3'} size={20} className={'mr-6'} />
                <div>
                  <p className={'text-md'}>
                    {footerComponent?.contact?.phone_num}
                  </p>
                  <p className={'text-md'}>
                    {footerComponent?.contact?.time_of_service}
                  </p>
                  <p className={'text-md'}>{footerComponent?.contact?.day}</p>
                </div>
              </div>
              <div className={'mr-4 xl:mr-0 xl:w-3/4 flex flex-row mb-6'}>
                <MdEmail color={'#a3a3a3'} size={28} className={'mr-2'} />
                <Link
                  href={`${footerComponent?.contact?.email?.link}`}
                  data-tracking-id="email-subscribe-link"
                  className={'text-md ml-2 mt-0.5 text-fontGrey'}
                >
                  {footerComponent?.contact?.email?.label}
                </Link>
              </div>
            </div>
          </div>
          <div className={'hidden xl:block w-px h-60 mx-2 bg-bgDarkGrey'}></div>
        </div>
        <div className={'w-full xl:w-1/2 flex flex-row justify-between'}>
          <div className={''}>
            <h4 className={'font-medium text-lg xl:text-3xl mb-5'}>
              {footerComponent?.texts?.connect}
            </h4>
            <div className="w-full flex flex-row">
              {responseStatus === 200 && (
                <div className={'mb-8'}>
                  Thanks, you are already subscribed.
                </div>
              )}
              {responseStatus === (400 || 500) && (
                <>
                  <div>Something went wrong, please try again.</div>
                  <form className="w-full" onSubmit={subscribe}>
                    <input
                      type="email"
                      onInvalid={(F) =>
                        (F.target as HTMLInputElement).setCustomValidity(
                          'Please enter valid email'
                        )
                      }
                      onInput={(F) =>
                        (F.target as HTMLInputElement).setCustomValidity('')
                      }
                      pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i"
                      className={
                        'focus:border-2 focus:border-primary w-3/4 rounded-l-lg h-10 xl:h-11 pl-3 text-black text-[18px] xl:text-[18px] sm:text-[16px]'
                      }
                      placeholder={'Email Signup for Special Offers'}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <button
                      className={
                        'w-1/4 text-white bg-primary hover:bg-hoverPrimary focus:outline-none focus:ring-white rounded-r-lg px-2 xl:px-5 text-center xl:text-[18px] sm:text-[16px] h-10 xl:h-11'
                      }
                    >
                      Subscribe
                    </button>
                  </form>
                </>
              )}
              {responseStatus === 0 && (
                <form className="w-full" onSubmit={subscribe}>
                  <input
                    type="email"
                    onInvalid={(F) =>
                      (F.target as HTMLInputElement).setCustomValidity(
                        'Please enter valid email'
                      )
                    }
                    onInput={(F) =>
                      (F.target as HTMLInputElement).setCustomValidity('')
                    }
                    pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i"
                    ref={inputRef}
                    className={
                      'focus:border-2 focus:border-primary w-3/4 rounded-l-lg h-10 xl:h-11 pl-3 text-black xl:text-[18px] sm:text-[16px] '
                    }
                    placeholder={'Email Signup for Special Offers'}
                    data-tracking-id="email-subscribe-box"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button
                    data-tracking-id="email-subscribe-button"
                    className={
                      'w-1/4 text-white bg-primary hover:bg-hoverPrimary focus:outline-none focus:ring-white rounded-r-lg px-2 xl:px-5  text-center xl:text-[18px] sm:text-[16px]  h-10 xl:h-11'
                    }
                  >
                    Subscribe
                  </button>
                </form>
              )}
            </div>
            {responseStatus !== 200 && (
              <p className={'text-xs xl:text-sm mt-2 text-fontGrey'}>
                {footerComponent?.texts?.by_clicking_subscribe}{' '}
                <Link
                  href={`${footerComponent?.social_links?.privacy_policy?.link}`}
                  className={'text-primary'}
                >
                  {footerComponent?.social_links?.privacy_policy?.label}
                </Link>
              </p>
            )}
            <div
              className={
                'mt-8 w-full flex flex-wrap xl:flex-row items-center ml-1 mr-1 justify-between font-thin'
              }
              data-tracking-id="social-icons"
            >
              <Link
                href={`${footerComponent?.social_links?.facebook?.link}`}
                className={'lg:w-fit w-1/4 flex flex-col'}
              >
                <ImFacebook2
                  color={'#fff'}
                  className="w-8 h-8 ml-1 xl:w-10 xl:h-10"
                />
                <p className={'text-xs xl:text-sm mt-2'}>
                  {footerComponent?.social_links?.facebook?.label}
                </p>
              </Link>
              <Link
                href={`${footerComponent?.social_links?.instagram?.link}`}
                className={'lg:w-fit w-1/4 flex flex-col'}
              >
                <GrInstagram
                  color={'#fff'}
                  className="w-8 h-8 ml-1 xl:w-10 xl:h-10"
                />
                <p className={'text-xs xl:text-sm mt-2'}>
                  {footerComponent?.social_links?.instagram?.label}
                </p>
              </Link>
              <Link
                href={`${footerComponent?.social_links?.twitter?.link}`}
                className={'lg:w-fit w-1/4 flex flex-col'}
              >
                {TwitterIcon && (
                  <Image
                    src={TwitterIcon ? `https:${TwitterIcon}` : TwitterIcon}
                    className="w-8 h-8 xl:w-10 xl:h-10"
                    alt="Twitter Icon"
                    width={56}
                    height={37}
                  />
                )}
                <p className={'text-xs ml-2 xl:ml-3 xl:text-sm mt-2'}>
                  {footerComponent?.social_links?.twitter?.label}
                </p>
              </Link>
              <Link
                href={`${footerComponent?.social_links?.youtube?.link}`}
                className={'lg:w-fit w-1/4 flex flex-col'}
              >
                <GrYoutube
                  color={'#fff'}
                  className="w-8 h-8 ml-1 xl:w-10 xl:h-10"
                />
                <p className={'text-xs xl:text-sm mt-2'}>
                  {footerComponent?.social_links?.youtube?.label}
                </p>
              </Link>
              <Link
                href={`${footerComponent?.social_links?.blog?.link}`}
                className={'lg:w-fit w-1/4 flex flex-col'}
              >
                <FaRss
                  color={'#fff'}
                  className="w-8 h-8 xl:w-10 mt-3 xl:mt-0 xl:h-10"
                />
                <p className={'text-xs xl:text-sm mt-2'}>
                  {footerComponent?.social_links?.blog?.label}
                </p>
              </Link>
            </div>
            <div
              className={
                'mt-8 w-full flex flex-row ml-1 flex-wrap xl:flex-nowrap items-center'
              }
            >
              <Image
                src={
                  'https://cdn.shopify.com/s/files/1/0008/9296/0821/files/icoVisa_medium.png?v=1662594616'
                }
                alt={'Visa'}
                className="mr-2"
                width={56}
                height={37}
              />
              <Image
                src={
                  'https://cdn.shopify.com/s/files/1/0008/9296/0821/files/icoMastercard_83a562e3-e5a6-48a9-9254-366b93c9a5c0_medium.png?v=1662594616'
                }
                alt={'Mastercard'}
                className="mr-2"
                width={56}
                height={37}
              />
              <Image
                src={
                  'https://cdn.shopify.com/s/files/1/0008/9296/0821/files/icoAmex_medium.png?v=1662594615'
                }
                alt={'American Express'}
                className="mr-2"
                width={56}
                height={37}
              />
              <Image
                src={
                  'https://cdn.shopify.com/s/files/1/0008/9296/0821/files/icoDiscover_medium.png?v=1662594616'
                }
                alt={'Discover'}
                width={56}
                height={37}
              />
            </div>
          </div>
          <div className={'hidden xl:block w-px h-60 mx-2 bg-bgDarkGrey'}></div>
        </div>
        <div
          className={
            'w-full xl:w-1/4 flex flex-col items-start pt-4 xl:pt-0 xl:pl-2 text-fontGrey'
          }
        >
          {footerRedirectLinksData?.map((redirect, index) => (
            <Link
              key={index}
              href={`${redirect.link}`}
              className={'font-medium text-sm xl:text-lg mb-1'}
            >
              {redirect.label}
            </Link>
          ))}
          <button
            id="ot-sdk-btn"
            suppressHydrationWarning={true}
            className="ot-sdk-show-settings font-medium text-sm xl:text-lg mb-1 mt-2"
          >
            Do Not Sell My Personal Information
          </button>
        </div>
      </div>
    </section>
  );
}
