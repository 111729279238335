'use client';
import { useContext, useState } from 'react';
import Logo from '../../atoms/Logo';
import FilterDevices from '../../molecules/FilterDevices';
import BuySellButtons from '../../molecules/BuySellButtons';
import CartContainer from '../../molecules/CartContainer';
import {
  HeaderContextProps,
  HeaderProductsContext,
} from '@/context/HeaderContext';

export default function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const { cartHeaderText } = useContext<HeaderContextProps>(
    HeaderProductsContext
  );

  const setShowMenuHandler = (show: boolean) => {
    setShowMenu(show);
  };
  return (
    <div className="sectionHeader">
      <div>
        <header>
          <div className="headerRow justify-between mr-0 xl:mr-[10rem] pl-[6px] pr-[22px]">
            <div className="logoColumn">
              <Logo />
            </div>
            <div className="mb-1.5 xl:mb-0 z-100 xl:accountColumn">
              <CartContainer cartHeaderText={cartHeaderText} />
            </div>
          </div>
        </header>
        <nav className="rootNav">
          <div className="container">
            <BuySellButtons
              showMenuHandler={setShowMenuHandler}
              showMenu={showMenu}
            />
          </div>
          <div className="tabContent">
            <FilterDevices
              showMenu={showMenu}
              closeNav={() => setShowMenu(false)}
            />
          </div>
        </nav>
      </div>
    </div>
  );
}
