import { getUrls } from "./Urls"

const url = getUrls()
export const GTM_ID = url.gtmId

export function gtmEvent (eventName, eventProps) {
  window?.dataLayer?.push({
    event: eventName,
    eventProps,
  })
}