import Footer from '@/components/organisms/footer';
import FooterLegalSection from '@/components/organisms/footerLegalSection';
import FooterPopularSearches from '@/components/organisms/footerPopularSearches';
import Header from '@/components/organisms/header';
import { useRouter } from 'next/router';
import DarkHeader from '@/components/organisms/header/DarkHeader';

interface MainLayoutProps {
  children: React.ReactNode | undefined;
}
export default function MainLayout(props: MainLayoutProps) {
  const { children } = props;
  const router = useRouter();
  const currentPage = router.pathname;

  return (
    <>
      <header>
        {currentPage === '/checkout' || currentPage === '/login' ? (
          <DarkHeader />
        ) : (
          <Header />
        )}
      </header>
      <main className={'w-full flex flex-col'}>{children}</main>
      {currentPage !== '/login' && currentPage !== '/checkout' && (
        <footer>
          <Footer />
          <FooterPopularSearches />
          <FooterLegalSection />
        </footer>
      )}
    </>
  );
}
