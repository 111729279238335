import React, { useContext } from 'react';
import { FooterContext, FooterContextProps } from '@/context/FooterContext';
import Link from 'next/link';

export default function FooterPopularSearches() {
  const { footerData } = useContext<FooterContextProps>(FooterContext);
  const handleLink = (link: string) => {
    window.location.href = link;
  };

  return (
    <section className={'w-full bg-black'}>
      <div
        className={
          'w-full flex flex-col xl:flex-row px-4 py-4 xl:px-48 xl:py-10'
        }
      >
        <div
          className={'w-full xl:w-4/4 flex flex-col items-start text-fontGrey'}
        >
          <ul
            className={
              'w-full flex-col  xl:flex xl:gap-24 xl:flex-row xl:flex-wrap'
            }
          >
            <h4
              className={'font-medium text-lg xl:text-xl mt-2 xl:mt-1 xl:py-1'}
            >
              Popular Searches
            </h4>
            {footerData?.map((search, index) => (
              <li key={index}>
                <Link
                  href={`${search?.link}`}
                  data-tracking-id={`popular-searches-${index + 1}`}
                  className={
                    ' flex py-1 xl:mt-1  text-base  xl:text-lg  underline cursor-pointer'
                  }
                  onClick={() => handleLink(search?.link)}
                >
                  {search.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
