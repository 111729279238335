import Image from 'next/image';
import Link from 'next/link';
import { ContentContext, ContentContextProps } from '@/context/ContentContext';
import { useContext } from 'react';

export default function Logo() {
  const { linksData } = useContext<ContentContextProps>(ContentContext);

  return (
    <div>
      <Link href="/" className="siteLogo md:ml-[170px]">
        {linksData?.logo?.link && (
          <Image
            src={linksData?.logo?.link}
            alt="logo"
            itemProp="logo"
            width={120}
            height={24}
          />
        )}
        <h1 className="hidden">{linksData?.logo?.name}</h1>
      </Link>
    </div>
  );
}
