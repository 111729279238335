import {getContentLinksContent} from "@/lib/contentful";

type SectionDataProps = {
  fields: {};
  sys: {};
  metadata: {};
};

export async function getContentLinks() {
  try {
    const contentLinksRes = await getContentLinksContent();

    const contentLinks = contentLinksRes.items.map(
      (item: SectionDataProps) => item.fields
    );
    const contentLinksData = contentLinks ?? null;

    if (!contentLinksData) {
      throw new Error('Data not found');
    }
    return {
      props: {
        contentLinksData: contentLinksData[0],
        error: null,
      },
    };
  } catch (error) {
    return {
      props: {
        error,
      },
    };
  }
}


export function getUrls() {
  const gazelleUrl = process?.env.NEXT_PUBLIC_DS_STORE_ENV_URL || '';
  const shopifyStoreToken = process?.env.NEXT_PUBLIC_SHOPIFY_STORE_TOKEN  || '';
  const subscriptionShopifyUrl = process?.env.NEXT_PUBLIC_STORE_URL || '';
  const oneTrustId = process?.env.NEXT_PUBLIC_ONE_TRUST_DATAID || '';
  const gtmId = process?.env.NEXT_PUBLIC_GTM_ID || '';
  const dataLogId = process?.env.NEXT_PUBLIC_DATADOG_PUBLIC_APPLICATION_ID || '';
  const dataDogClientToken = process?.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '';
  const yoptoAppKey = process?.env.NEXT_PUBLIC_YOTPO_APP_KEY || '';
  const dsStore = process?.env.NEXT_PUBLIC_DS_STORE_ENV_URL || '';
  const googleApiKey = process?.env.NEXT_PUBLIC_GOOGLE_API_KEY || '';
  const authEndpoint = process?.env.NEXT_PUBLIC_LOCAL_AUTH_ENDPOINT || '';
  const contentfulSpacedId = process?.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID || '';
  const yoptoDomain = process?.env.NEXT_PUBLIC_YOTPO_DOMAIN || '';
  const yoptoSecretKey = process?.env.NEXT_PUBLIC_YOTPO_SECRET_KEY || '';
  const yoptoUToken = process?.env.NEXT_PUBLIC_YOTPO_UTOKEN || '';
  const spreedlyKey = process?.env.NEXT_PUBLIC_SPREEDLY_KEY || '';
  const contentfulAccessToken = process?.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN || '';
  const contentfulEnvoirment = process?.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT || '';
  const publicEnvoirment = process?.env.NEXT_PUBLIC_ENVIRONMENT || '';
  const nodeEnv = process?.env.NODE_ENV || '';
  const eligibilityCheckUrl = process?.env.NEXT_PUBLIC_ELIGIBILITY_CHECK_URL || '';
  const logLevel = process?.env.LOG_LEVEL || '';
  return {
    gazelleUrl,
    subscriptionShopifyUrl,
    oneTrustId,
    gtmId,
    dataLogId,
    dataDogClientToken,
    yoptoAppKey,
    yoptoDomain,
    dsStore,
    googleApiKey,
    authEndpoint,
    contentfulSpacedId,
    yoptoSecretKey,
    yoptoUToken,
    shopifyStoreToken,
    spreedlyKey,
    contentfulAccessToken,
    contentfulEnvoirment,
    publicEnvoirment,
    nodeEnv,
    eligibilityCheckUrl,
    logLevel

  };
}