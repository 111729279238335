import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

interface BuySellButtonProps {
  showMenuHandler: (value: boolean) => void;
  showMenu: boolean;
}

export default function BuySellButtons(props: BuySellButtonProps) {
  return (
    <>
      <ul className="navTabs">
        <li className="activeTab">
          <a className="buyfirst">
            {!props.showMenu ? (
              <AiOutlineMenu
                className="mb-2"
                onClick={() => props.showMenuHandler(true)}
              />
            ) : (
              <AiOutlineClose
                className="mb-2"
                onClick={() => props.showMenuHandler(false)}
              />
            )}
          </a>
          <a href="https://buy.gazelle.com" className="buysecond">
            <span>Buy</span>
          </a>

          <a className="buythird" href="https://buy.gazelle.com">
            Buy
          </a>
        </li>

        <li className="inActiveTab">
          <a className="sell" href="https://gazelle.com/trade-in">
            SELL
          </a>
        </li>
      </ul>
    </>
  );
}
