import { ErrorDataProps } from '#/globalTypes';
import { createContext, PropsWithChildren, useState } from 'react';

export interface ErrorContextProps {
  errorData: ErrorDataProps[];
  setErrorData: React.Dispatch<React.SetStateAction<ErrorDataProps[]>>;
}

export const ErrorContext = createContext<ErrorContextProps>(
  {} as ErrorContextProps
);

const ErrorProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [errorData, setErrorData] = useState<ErrorDataProps[]>([]);

  return (
    <ErrorContext.Provider value={{ errorData, setErrorData }}>
      {children}
    </ErrorContext.Provider>
  );
};
export default ErrorProvider;
