import React, { useContext } from 'react';
import { FooterContext, FooterContextProps } from '@/context/FooterContext';

export default function FooterLegalSection() {
  const { footerCopyrightMsg } = useContext<FooterContextProps>(FooterContext);
  const currentYear = new Date().getFullYear();

  return (
    <section
      className={'w-full px-4 pt-4 pb-8 xl:px-48 xl:pt-5 xl:pb-16 bg-fontGrey'}
      data-testid={'legal-section'}
    >
      <p className={'text-base text-start mb-28 xl:mb-0'}>
        &copy; 2014 - {currentYear}, {footerCopyrightMsg}
      </p>
    </section>
  );
}
