import { FAQsProps } from "#/globalTypes";
import { createContext, PropsWithChildren, useState } from "react";

export interface FAQContextProps {
  faqData: FAQsProps[];
  setFAQData: React.Dispatch<React.SetStateAction<FAQsProps[]>>;
}

export const FAQContext = createContext<FAQContextProps>({} as FAQContextProps);

const FAQProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [faqData, setFAQData] = useState<FAQsProps[]>([]);

  return (
    <FAQContext.Provider value={{ faqData, setFAQData }}>
      {children}
    </FAQContext.Provider>
  );
};
export default FAQProvider;
