import { createApolloClient } from './ApolloClient';
import {
  GET_ONETIME_INITIATION_FEE_ITEM,
  GET_CUSTOMER_INFO,
} from '@/graphql/Queries';
import {
  ASSOCIATE_CUSTOMER_WITH_CHECKOUT,
  COMPLETE_CHECKOUT,
} from '@/graphql/Mutations';

import logger from '@/lib/logger';

export async function getInitiationFee() {
  const apolloClient = await createApolloClient();
  try {
    const initiationFeeItem = await apolloClient.query({
      query: GET_ONETIME_INITIATION_FEE_ITEM,
    });
    return initiationFeeItem;
  } catch (error) {
    logger.error(error, 'error getting initiation fee item');
    return null;
  }
}

export const associateCustomerWithCheckout = (
  checkoutId: string,
  customerAccessToken: string
) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const apolloClient = await createApolloClient();
      const newcheckout = await apolloClient.mutate({
        mutation: ASSOCIATE_CUSTOMER_WITH_CHECKOUT,
        variables: {
          checkoutId,
          customerAccessToken,
        },
      });
      resolve(newcheckout);
    } catch (error) {
      reject('ERROR ASSOCIATING CUSTOMER TO CHECKOUT');
    }
  });
};

export const completeCheckout = (
  vaultId: any,
  checkoutId: string,
  amount: string,
  firstName: string,
  lastName: string,
  address1: string,
  address2: string,
  province: string,
  country: string,
  city: string,
  zip: string
) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const apolloClient = await createApolloClient();
      const newcheckout = await apolloClient.mutate({
        mutation: COMPLETE_CHECKOUT,
        variables: {
          checkoutId,
          payment: {
            paymentAmount: {
              amount,
              currencyCode: 'USD',
            },
            idempotencyKey: '123',
            billingAddress: {
              firstName,
              lastName,
              address1,
              address2,
              province,
              country,
              city,
              zip,
            },
            vaultId,
          },
        },
      });
      resolve(newcheckout);
    } catch (error) {
      reject('ERROR COMPLETING CHECKOUT');
    }
  });
};

export const getShopifyCustomer = (customerAccessToken: string) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const apolloClient = await createApolloClient();
      const customer = await apolloClient.query({
        query: GET_CUSTOMER_INFO,
        variables: {
          customerAccessToken,
        },
      });
      resolve(customer);
    } catch (error) {
      reject('ERROR RETREIVING CUSTOMER');
    }
  });
};
